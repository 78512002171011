module.exports = {
  siteMetadata: {
    title: 'Irriglobe',
    author: 'Irriglobe',
    description:
      "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation!",
    siteUrl: 'https://www.irriglobe.com',
    twitter: 'Irriglobe',
    logo: '/img/logo.png',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/pages`,
        name: 'pages',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/components`,
        name: 'components',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/assets/img`,
        name: 'assets-images',
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/static/img/slider`,
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
              withWebp: true
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
        resolve: `@gatsby-contrib/gatsby-plugin-elasticlunr-search`,
        options: {
            // Fields to index
            fields: [
                'title',
                'description',
                'vendor'
            ],
            // How to resolve each field's value for a supported node type
            resolvers: {
                // For any node of type MarkdownRemark, list how to resolve the fields' values
                MarkdownRemark: {
                    title: node => node.frontmatter.title,
                    description: node => node.frontmatter.description,
                    path: node => node.frontmatter.path,
                },
            },
        },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Irriglobe',
        short_name: 'Irriglobe',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#1992d1',
        display: 'minimal-ui',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '83x101',
            type: 'image/png',
          }
        ],
      },
    },
    {
      resolve: `gatsby-plugin-google-remarketing`,
      options: {
        google_conversion_id: 1064844478,
        google_remarketing_only: true,
        head: false
      },
    },
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: '117487128922397',
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-uninline-styles',
    'gatsby-plugin-sharp',
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        serialize: ({site, allSitePage}) => {
          return allSitePage.edges.map((page) => ({
            url: `${site.siteMetadata.siteUrl}${page.node.path.replace(/\/$/, "")}`, // Removes trailing slash
            changefreq: "daily",
            priority: page.path === "/" ? 1.0 : 0.7,
          }))
        },
      }
    },
    'gatsby-plugin-twitter',
    'gatsby-transformer-sharp',
    'gatsby-plugin-styled-components',
    'gatsby-plugin-layout'
  ],
}
